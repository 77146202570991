.wrap{
width:250px;
height:50px;
display: flex;
flex-direction: row;
justify-content: center;
margin:2% auto;
cursor: pointer;
transition: all ease 1.1s;
background: linear-gradient(to right, #083d08, #043108);
padding:0.6rem 1.5rem;
border-radius: 25px;
box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.734);
align-items: center;
}
.wrap:hover{
    transform: scale(1.1);
}
.icon{
    display: flex;
    width:30%;
    align-items: center;
    justify-content: center;
}

.text{
    font-size:1.2rem;
    font-family: "Camping";
    text-align: center;
    margin:1% auto;
    color:rgb(255, 226, 203);
    align-items: center;
    display: flex;
    direction: rtl;
    justify-content: center;
    width:100%;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);

}