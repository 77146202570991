.title{
    font-size:4rem;
    font-family: "Camping";
    text-align: center;
    margin:2% auto;
    direction:rtl;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
}
.center{
    display: flex;
    width:20%
}
.image{
    width:100%;
    object-fit: contain;
    border-radius: 150px 0 150px 0;
    box-shadow: 0 0 10px 1px #333;
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:1% auto;
    justify-content: space-evenly;
}
.description{
    font-size:2.5rem;
    font-family: "Camping";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    width:100%;
}
.more{
    font-size:1.4rem;
    font-family: "Camping";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    width:100%;
}
.explain{
    font-size:1.4rem;
    font-family: "AssistantR";
    text-align: center;
    margin:1% auto;
    direction: rtl;
    text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    width:100%;
 margin-right: auto;
}
.wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width:100%;
}
.column{
    display: flex;
    flex-direction: column;
   width:40%;
}

@media only screen and (max-width: 350px) {

    .title{
        font-size:1.5rem;
        font-family: "Camping";
        text-align: center;
        margin:6% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:80%
    }
    .image{
        width:100%;
        margin:2% auto;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;

    }
    .description{
        font-size:2rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.4rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.1rem;
        font-family: "AssistantR";
        text-align: center;
        margin:3% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:90%;
    }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {

    .title{
        font-size:1.8rem;
        font-family: "Camping";
        text-align: center;
        margin:6% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:70%
    }
    .image{
        width:100%;
        margin:2% auto;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;

    }
    .description{
        font-size:2.2rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.5rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.15rem;
        font-family: "AssistantR";
        text-align: center;
        margin:3% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:90%;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .title{
        font-size:2.2rem;
        font-family: "Camping";
        text-align: center;
        margin:6% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:50%
    }
    .image{
        width:100%;
        margin:2% auto;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;

    }
    .description{
        font-size:2.5rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.6rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:3% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:90%;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {

    .title{
        font-size:2.5rem;
        font-family: "Camping";
        text-align: center;
        margin:6% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:40%
    }
    .image{
        width:100%;
        margin:2% auto;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;

    }
    .description{
        font-size:2.8rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.6rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:3% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:90%;
    }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {

    .title{
        font-size:2.8rem;
        font-family: "Camping";
        text-align: center;
        margin:3% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:40%
    }
    .image{
        width:100%;
        margin:2% auto;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;

    }
    .description{
        font-size:3rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.7rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.25rem;
        font-family: "AssistantR";
        text-align: center;
        margin:3% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:90%;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {

    .title{
        font-size:2.5rem;
        font-family: "Camping";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:30%
    }
    .image{
        width:100%;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .description{
        font-size:2.5rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.4rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.1rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin:3% auto;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:50%;
    }
    
}

@media only screen and (min-width: 850px) and (max-width: 950px) {

    .title{
        font-size:2.8rem;
        font-family: "Camping";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:30%
    }
    .image{
        width:100%;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .description{
        font-size:3rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.5rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.1rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin:3% auto;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:50%;
    }
    
}
@media only screen and (min-width: 950px) and (max-width: 1150px) {

    .title{
        font-size:3rem;
        font-family: "Camping";
        text-align: center;
        margin:2% auto;
        direction:rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
    }
    .center{
        display: flex;
        width:30%
    }
    .image{
        width:100%;
        object-fit: contain;
        border-radius: 150px 0 150px 0;
        box-shadow: 0 0 10px 1px #333;
    }
    .row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:1% auto;
        justify-content: space-evenly;
    }
    .description{
        font-size:3.3rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .more{
        font-size:1.7rem;
        font-family: "Camping";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
    }
    .explain{
        font-size:1.2rem;
        font-family: "AssistantR";
        text-align: center;
        margin:1% auto;
        direction: rtl;
        text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.4);
        width:100%;
     margin-right: auto;
    }
    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin:3% auto;
        width:100%;
    }
    .column{
        display: flex;
        flex-direction: column;
       width:50%;
    }
    
}
